.footer {
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;
  height: 200px;
  background-color: $color-3;
  font-size: 1.3rem;
  color: white;
  font-weight: 500;

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    width: 50%;
    height: 80%;
    padding-bottom: 0.5rem;
  }

  &__logo {
    width: 122px;
    height: auto;
  }
}

// MEDIA QUERIES

@media only screen and (max-width: 800px) {
  .footer {
    align-items: center;
    font-size: 1rem;
    &__box {
      height: 70%;
    }
  }
}

@media only screen and (max-width: 530px) {
  .footer {
    &__box {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .footer {
    font-size: 0.8rem;
  }
}
