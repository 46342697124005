.header {
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// MEDIA QUERIES

@media only screen and (max-width: 1000px) {
  .header {
    .logo {
      width: 180px;
      height: auto;
    }
  }
}

@media only screen and (max-width: 800px) {
  .header {
    .logo {
      width: 145px;
      height: auto;
    }
  }
}

@media only screen and (max-width: 500px) {
  .header {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 360px) {
  .header {
    flex-direction: column;
    justify-content: center;
    row-gap: 1.3rem;

    .navigation {
      width: 90%;
      justify-content: space-around;
    }
  }
}
