@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

$font-1: "Montserrat", sans-serif;

$color-1: #ff6060;
$color-2: #f6f6f6;
$color-3: #000000;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0 auto;
  max-width: 1500px;
  font-family: $font-1;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.wrapper {
  margin: 0 auto;
  width: 86%;
}

// MEDIA QUERIES

@media only screen and (max-width: 500px) {
  .wrapper {
    width: 90%;
  }
}
