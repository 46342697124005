// GENERAL COLLAPSABLE COMPONENT
.collapsable {
  cursor: pointer;

  // Prevent h3 from bugging collapse animation in case of click on the h3
  h3 {
    pointer-events: none;
  }

  &__title-wrapper {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: $color-1;
    transform: translateY(7px);
  }

  &__title {
    font-weight: 500;
  }

  &__description {
    background-color: $color-2;
    color: $color-1;
    animation: opened-description 0.6s ease-out forwards;
  }

  .opened-arrow {
    animation: opened-arrow 0.6s ease-in-out forwards;
  }
}

// ABOUT PAGE COLLAPSABLE
.collapsable__about-group {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 2rem;
  width: 85%;
  row-gap: 2.5rem;

  .collapsable {
    &__title-wrapper {
      padding: 0.8rem 1.5rem;
      border-radius: 5px;
    }

    &__title {
      font-size: 1.5rem;
    }

    &__description {
      padding: 2rem 1.5rem 1rem;
      border-radius: 5px;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 36px;
    }
  }
}

// LOGEMENT PAGE COLLAPSABLE
.collapsable__logement-group {
  display: flex;
  justify-content: space-between;

  .collapsable {
    width: 47%;

    &__title-wrapper {
      padding: 0.8rem 1.2rem;
      border-radius: 10px;
    }

    &__title {
      font-size: 1.1rem;
    }

    &__description {
      padding: 2.2rem 1.5rem 1rem;
      min-height: 250px;
      height: fit-content;
      border-radius: 10px;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 26px;
    }
  }
}

// ANIMATIONS
@keyframes opened-description {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes opened-arrow {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

// MEDIA QUERIES
@media only screen and (max-width: 800px) {
  .collapsable {
    &__about-group,
    &__logement-group {
      width: 100%;

      .collapsable {
        width: 100%;
        &__title,
        &__description {
          font-size: 1rem;
          line-height: 20px;
        }

        &__description {
          min-height: 143px;
          padding: 1.9rem 1.2rem 0.7rem;
        }
      }
    }
  }

  .collapsable__logement-group {
    flex-direction: column;
    row-gap: 1.2rem;
    .collapsable__description {
      min-height: 143px;
      height: auto;
    }
  }
}

@media only screen and (max-width: 450px) {
  .collapsable {
    &__about-group,
    &__logement-group {
      row-gap: 1.2rem;
      .collapsable {
        &__title-wrapper {
          padding: 0.5rem 1rem;
          border-radius: 5px;
        }

        &__title {
          font-size: 0.9rem;
        }

        &__arrow {
          width: 18px;
          height: auto;
        }

        &__description {
          min-height: 143px;
          padding: 1.4rem 0.6rem 0.3rem;
          line-height: 17px;
          font-size: 0.75rem;
        }
      }
    }
  }
}
