.description {
  display: flex;
  justify-content: space-between;

  .title,
  .location {
    font-weight: 500;
    color: $color-1;
  }

  .title {
    font-size: 2.2rem;
    padding: 0.3rem 0;
    font-weight: 500;
  }

  .location {
    font-size: 1.2rem;
  }

  .tag-list {
    display: flex;
    margin: 1.5rem 0;
    gap: 0.5rem;
  }

  .tag {
    padding: 0.4rem 1.2rem 0.3rem;
    min-width: 115px;
    text-align: center;
    background-color: $color-1;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 10px;
  }

  .profileAndRating {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.3rem;

    .host {
      display: flex;
      width: min-content;
      align-items: center;
      text-align: right;
      gap: 0.7rem;

      .name {
        color: $color-1;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 26px;
      }

      .picture {
        width: 64px;
        height: auto;
        border-radius: 50%;
      }
    }

    .rating-list {
      display: flex;
      gap: 0.5rem;

      .full-star {
        fill: $color-1;
      }

      .empty-star {
        fill: #e3e3e3;
      }
    }
  }
}

// MEDIA QUERIES
@media only screen and (max-width: 750px) {
  .description {
    flex-direction: column;

    .title {
      font-size: 1.8rem;
      padding: 0;
    }

    .location {
      font-size: 1rem;
      margin: 1rem 0;
    }

    .profileAndRating {
      margin: 1rem 0;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    .tag-list {
      flex-wrap: wrap;
      margin: 0;
    }

    .tag {
      min-width: 100px;
      font-size: 0.8rem;
      border-radius: 5px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .description {
    .title {
      font-size: 1.15rem;
    }

    .location {
      margin: 0.5rem 0 0.9rem 0;
      font-size: 0.9rem;
    }

    .profileAndRating {
      margin: 1rem 0;

      .host {
        .name {
          font-size: 0.8rem;
          line-height: 17px;
        }
        .picture {
          width: 38px;
          height: auto;
        }
      }

      .rating-list {
        gap: 0.2rem;

        .full-star,
        .empty-star {
          width: 18px;
          height: auto;
        }
      }
    }

    .tag {
      padding: 0.4rem 0.6rem;
      font-size: 10px;
    }
  }
}
