.accomodation {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 3.3rem;
    row-gap: 3.3rem;
    justify-content: space-between;
    background-color: #f6f6f6;
    border-radius: 25px;
  }

  &__thumbnail {
    display: flex;
    align-items: flex-end;
    width: 340px;
    height: 340px;
    cursor: pointer;
    border-radius: 10px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all 0.5 ease-in-out;

    &:hover {
      animation: hover 0.2s ease-in-out forwards;

      .accomodation__title {
        animation: hover-title 0.2s ease-in-out forwards;
      }
    }
  }

  &__title {
    font-size: 1.1rem;
    font-weight: 400;
    margin: 1rem;
    color: white;
  }
}

// ANIMATIONS

@keyframes hover {
  from {
    transform: scale(1);
    box-shadow: none;
  }

  to {
    transform: scale(1.02);
    box-shadow: 1px 2px 7px gray;
  }
}

@keyframes hover-title {
  from {
    font-size: 1.1rem;
    font-weight: 400;
    color: white;
  }

  to {
    font-size: 1.15rem;
    font-weight: 500;
    color: $color-1;
  }
}

// MEDIA QUERIES

@media only screen and (max-width: 1340px) {
  .accomodation {
    &__wrapper {
      padding: 2.5rem;
      row-gap: 2.5rem;
    }

    &__thumbnail {
      width: 300px;
      height: 300px;
    }
  }
}

@media only screen and (max-width: 1170px) {
  .thumbnail-invisible {
    display: none;
  }

  .accomodation {
    &__wrapper {
      justify-content: space-around;
    }

    &__thumbnail {
      width: 340px;
      height: 340px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .accomodation {
    &__wrapper {
      justify-content: center;
    }

    // Thumbnail link is the anchor tag created by React through onClick
    &__thumbnail-link {
      width: 85%;
    }

    &__thumbnail {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .accomodation {
    &__wrapper {
      padding: 0;
      background-color: transparent;
      row-gap: 1.3rem;
    }

    &__thumbnail-link {
      width: 100%;
    }

    &__thumbnail {
      height: 255px;
    }
  }
}

@media only screen and (max-width: 300px) {
  .accomodation {
    &__thumbnail {
      height: 235px;
    }

    &__title {
      font-size: 0.8rem;
    }
  }
}
