.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  margin: 50px 0 43px;
  border-radius: 25px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &__title {
    color: white;
    font-size: 3rem;
    font-weight: 500;
  }
}

// MEDIA QUERIES
@media only screen and (max-width: 1000px) {
  .banner {
    margin: 30px 0 23px;
    &__title {
      font-size: 2rem;
      padding: 0 2rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .banner {
    height: 150px;
  }
}

@media only screen and (max-width: 700px) {
  .banner {
    border-radius: 10px;
    height: 110px;
    &__title {
      font-size: 1.5rem;
    }
  }
}

// About page banner does not need media queries
.banner {
  &__about-page {
    height: 220px;
  }
}
