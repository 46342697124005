.navigation {
  display: flex;
  justify-content: space-between;
  width: 23%;
  font-size: 1.5rem;

  .active {
    text-decoration: underline $color-1;
  }

  .nav-link {
    color: $color-1;
    font-weight: 400;
  }
}

// MEDIA QUERIES

@media only screen and (max-width: 1100px) {
  .navigation {
    width: 27%;
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 800px) {
  .navigation {
    width: 40%;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 500px) {
  .navigation {
    width: 45%;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
}
